<template>
  <v-navigation-drawer
    right
    v-model="isOpen"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
  >
    <template v-slot:prepend>
      <v-toolbar color="color2 color2Text--text" tile dense class="top">
        <v-toolbar-title>Report Setting</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab
          small
          @click.stop="cancel(true)"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <v-card-text>
      <v-container class="px-0">
        <v-row dense>
          <v-col cols="12">
            <div>Columns</div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="6" v-for="h in headers" :key="h.value">
            <v-switch
              :label="h.text"
              v-model="h.show"
              color="success"
              class="mt-0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <div>Actions</div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="color3"
              text
              @click.stop="$emit('refresh-click')"
            >
              <v-icon small class="mr-3">fas fa-sync</v-icon> Refresh Report
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="color3"
              text
              @click.stop="$emit('download-click')"
            >
              <v-icon small class="mr-3">fas fa-download</v-icon>Download Report
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

  </v-navigation-drawer>
</template>

<script>

export default {
  props: ['report', 'headers'],
  data () {
    return {
      isOpen: false
    }
  },
  computed: {

  },
  methods: {
    cancel (close) {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
    }
  }
}
</script>
